import React from 'react';
import {Link} from 'gatsby';
import Img from "gatsby-image"

const ProjectPreview = (props) => {
        const allProjects = props.projectChildren.filter((child) => (
            child.className === 'Netwerkstatt__AstridWildner__Page__ImageGalleryPage'
        ));

        const allSortedProjects = allProjects.sort((a, b) => {
            //sort by project date, fall back to created
            const aDate = a.NetwerkstattImageGalleryPage.projectDate || a.created;
            const bDate = b.NetwerkstattImageGalleryPage.projectDate || b.created;

            return new Date(bDate) - new Date(aDate);

        });
        return allSortedProjects.map((child) => {
            const {title} = child.SilverStripeSiteTree;
            const link = child.link;
            const galleryItems = child.NetwerkstattImageGalleryPage.ImageGalleryItems;

            const extraPreviewPic = child.NetwerkstattImageGalleryPage.Picture;

            const firstGalleryPic = galleryItems.sort((a, b) =>
                a.NetwerkstattImageGalleryItem.sortOrder - b.NetwerkstattImageGalleryItem.sortOrder
            )[0].NetwerkstattImageGalleryItem;

            const previewPic = extraPreviewPic || firstGalleryPic.Image;

            const img = (previewPic.imageFile)
                ? <Img fluid={previewPic.imageFile.childImageSharp.fluid} className="w-full"/>
                : null;

            return (
                <section className="p-4 w-full sm:w-1/2 md:w-1/3">
                    <h3 className="font-bold"><Link to={link}>{title}</Link></h3>
                    <Link to={link}>
                        {img}
                    </Link>


                </section>
            )
        });

    }
;


export default ProjectPreview;