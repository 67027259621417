import React from "react"
import { graphql } from "gatsby"
import useSiteTreeContext from "silverstripe-gatsby-helpers/lib/hooks/useSiteTreeContext"
import Layout from "../Page"
import SEOTags from "../../components/SEOTags"
import Breadcrumbs from "../../components/Breadcrumbs"
import ProjectHolderPreview from "../../components/ProjectHolderPreview";
import ProjectPreview from "../../components/ProjectPreview";

const Page = ({ data: { silverStripeDataObject }}) => {
	const { title, content } = silverStripeDataObject.SilverStripeSiteTree;

	const  {getChildren, isLevel}  = useSiteTreeContext();
	const children = getChildren();
	const isLevel2 = isLevel(2);
	const hasSubnav = isLevel(2) || !!children.length;

	const projectChildren = silverStripeDataObject.SilverStripeSiteTree.Children;

	return (
	  <Layout>
	    <SEOTags pageTitle={title} />

		<div className={`content ${hasSubnav ? 'hasSidebar' : ''}`}>
			<div className="main">
				{isLevel2 &&
					<Breadcrumbs />
				}
	    		<h1 className="font-bold text-2xl mb-4">{title}</h1>
	    		<div className="font-serif htmltext" dangerouslySetInnerHTML={{__html: content}} />

				<ProjectHolderPreview projectHolderChildren={projectChildren} />

				<div className="flex flex-wrap -mx-4">
					<ProjectPreview projectChildren={projectChildren} />
				</div>
			</div>
		</div>
	  </Layout>
  	);
};

export default Page

export const pageQuery = graphql`
	query ($link: String!) {
		silverStripeDataObject(link: {eq: $link}, NetwerkstattImageGalleryItem: {}, NetwerkstattImageGalleryPage: {Picture: {imageFile: {absolutePath: {}}}}) {
			SilverStripeSiteTree {
				title
				content
				Children {
					className
					link
					created
					SilverStripeSiteTree {
						title
						content
						menuTitle
						Children {
							className
							link
							created
							SilverStripeSiteTree {
								title
								urlSegment
								menuTitle
							}
							NetwerkstattImageGalleryPage {
								excerpt
								projectDate
								Picture {
									imageFile {
										absolutePath
										childImageSharp {
											fluid(fit: COVER, maxHeight: 100, maxWidth: 200) {
												base64
												tracedSVG
												aspectRatio
												src
												srcSet
												srcWebp
												srcSetWebp
												sizes
												originalImg
												originalName
												presentationWidth
												presentationHeight
											}
										}
									}
									id
								}
								ImageGalleryItems {
									link
									NetwerkstattImageGalleryItem {
										Image {
											SilverStripeFile {
												fileFilename
											}
											imageFile {
												absolutePath
												childImageSharp {
													fluid(fit: COVER, maxHeight: 100, maxWidth: 200) {
														base64
														tracedSVG
														aspectRatio
														src
														srcSet
														srcWebp
														srcSetWebp
														sizes
														originalImg
														originalName
														presentationWidth
														presentationHeight
													}
												}
											}
										}
										sortOrder
									}
								}
							}
						}
					}
					NetwerkstattImageGalleryPage {
						excerpt
						projectDate
						Picture {
							imageFile {
								absolutePath
								childImageSharp {
									fluid(fit: COVER, maxHeight: 100, maxWidth: 200) {
										base64
										tracedSVG
										aspectRatio
										src
										srcSet
										srcWebp
										srcSetWebp
										sizes
										originalImg
										originalName
										presentationWidth
										presentationHeight
									}
								}
							}
							id
						}
						ImageGalleryItems {
							link
							NetwerkstattImageGalleryItem {
								Image {
									SilverStripeFile {
										fileFilename
									}
									imageFile {
										absolutePath
										childImageSharp {
											fluid(fit: COVER, maxHeight: 100, maxWidth: 200) {
												base64
												tracedSVG
												aspectRatio
												src
												srcSet
												srcWebp
												srcSetWebp
												sizes
												originalImg
												originalName
												presentationWidth
												presentationHeight
											}
										}
									}
								}
								sortOrder
							}
						}
					}
				}
			}
			NetwerkstattImageGalleryPage {
				Picture {
					imageFile {
						absolutePath
						childImageSharp {
							fluid(fit: COVER, maxHeight: 100, maxWidth: 200) {
								base64
								tracedSVG
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
								originalImg
								originalName
								presentationWidth
								presentationHeight
							}
						}
					}
					id
				}
			}
		}
	}
`;