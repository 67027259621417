import React from 'react';
import ProjectPreview from "./ProjectPreview";
import { Link } from 'gatsby';

const ProjectHolderPreview = (props) => {

    const allProjectHolder = props.projectHolderChildren.filter((child) => (
        child.className === 'Netwerkstatt__AstridWildner__Page__ProjektHolderPage'
    ));


    return allProjectHolder.map((child) => {
        const {title, Children} = child.SilverStripeSiteTree;
        const link = child.link;
        return (
            <section className="my-4">
                <h2 className="font-bold text-xl"><Link to={link}>{title}</Link></h2>
                <div className="flex flex-wrap -mx-4">
                    <ProjectPreview projectChildren={Children} />
                </div>

            </section>
        )
    });

};

ProjectHolderPreview.defaultProps = {
    projectHolderChildren: {}
};

export default ProjectHolderPreview;